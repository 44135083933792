import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import PairingTrain from '../pdfs/PairingTrain.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function

const generatePairingTrain = async (formData) => {
    const existingPdfUrl = PairingTrain;
    const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Register fontkit to enable custom font embedding
    pdfDoc.registerFontkit(fontkit);

    // Load a font that supports Hebrew characters
    const fontUrl = open_sans_font;
    const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
    const hebrewFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // rangeID_currentYear
    const rangeId = formData.rangeId + '_' + formData.registrationDate.split(',')[0].split('.')[2];
    const date = formData.registrationDate;

    const fullName = formData.fullName || 'שם משתמש';
    const teudatZehut = formData.teudatZehut || '123456789';
    const phone = formData.phoneNumber || '123456789';
    const imprintOnPistol = formData.imprintOnPistol || '123456789';
    const menufacturer = formData.pistolManufacturer || '';
    const origin = formData.origin || '';
    //   const model = formData.model || '';
    const caliber = formData.ammunitionCaliber || '9mm';
    const quantity = formData.bulletCount || '50';
    const hits = formData.hits || '0';

    const managedBy = formData.managedBy || 'שי לוי';
    const instructor = formData.instructor || 'שי לוי';

    firstPage.drawText(rangeId, { x: 130, y: 800, size: 9, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(date, { x: 130, y: 685, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(fullName, { x: 420, y: 629, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(teudatZehut, { x: 270, y: 629, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(phone, { x: 120, y: 629, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(menufacturer, { x: 260, y: 523, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(origin, { x: 120, y: 523, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    //   firstPage.drawText(model, { x: 90, y: 525, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(imprintOnPistol, { x: 420, y: 470, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(caliber, { x: 280, y: 470, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(quantity.toString(), { x: 130, y: 470, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(hits, { x: 280, y: 330, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

    firstPage.drawText(managedBy, { x: 410, y: 100, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });
    firstPage.drawText(instructor, { x: 120, y: 100, size: 11, font: hebrewFont, color: rgb(0, 0, 0) });

    const pdfBytes = await pdfDoc.save();
    const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'Pairing_Train', `Pairing_Train_${teudatZehut}_${rangeId}.pdf`);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `Pairing_Train_${teudatZehut}_${rangeId}.pdf`;
    link.click();
    return downloadUrl;
};

export default generatePairingTrain;
