import React from 'react';
import PropTypes from 'prop-types';

const UpdateNotification = ({ message, onRefresh }) => {
  const styles = {
    container: {
      position: 'fixed',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#333',
      color: '#fff',
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
      zIndex: 1000,
      maxWidth: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    message: {
      marginRight: '10px',
      fontSize: '16px',
    },
    button: {
      backgroundColor: '#007BFF',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '8px 15px',
      cursor: 'pointer',
      fontSize: '14px',
      marginRight: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.message}>{message}</p>
      <button style={styles.button} onClick={onRefresh}>
        Refresh
      </button>
    </div>
  );
};

UpdateNotification.propTypes = {
  message: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default UpdateNotification;
