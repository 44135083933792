import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, db } from './firebase';
import { logError } from '../components/firebase.js';
import '../styles/ClientManagementPopup.css';
import GunAmmoTrain from './GunAmmoTrain';
import Transaction from './Transaction';
import generateTransaction from '../PDFFileGenerators/TransactionGen';
import '../styles/SignaturePad.css';

import generateHealthDeclaration from '../PDFFileGenerators/HealthDecGen';
import generateSafeCommit from '../PDFFileGenerators/SafeCommitGen';
import generateGuardCertificate from '../PDFFileGenerators/GuardCertificateGen';
import generateTrainCertificate from '../PDFFileGenerators/TrainCertificateGen';


const ClientManagementPopup = ({ client, onClose, onUpdateClient }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [hits, setHits] = useState([0, 0, 0, 0, 0])

  const [activeCase, setActiveCase] = useState(null);//1-GunSale, 2-GunAmmoSale, 3-AmmoSale, 4-TrainCertificate, 5-GunPurchase
  const cases =
  {
    GUN_SALE: 1,
    GUN_AMMO_SALE: 2,
    AMMO_SALE: 3,
    TRAIN_CERTIFICATE: 4,
    GUN_PURCHASE: 5,
    MEFAL_RAOY: 6,
    PAIRING_TRAIN: 7,
  }
  const openCase = (caseNumber) => {
    setActiveCase(caseNumber);
  };



  const [formData, setFormData] = useState({
    ...client,
    bulletCount: 0,
    transaction: client.transaction || [],
    ammoSalePdf: client.AmmoSalePdf || client.ammoTransferUrl || '',
    gunSalePdf: client.gunSalePdf || client.gunTransferUrl || '',
    gunAmmoSalePdf: client.gunAmmoSalePdf || '',
    trainCertificatePdf: client.trainCertificatePdf || client.trainConfirmationUrl || '',
    gunPurchasePdf: client.gunPurchasePdf || '',
    pistolModel: client.pistolModel || '',
    pistolManufacturer: client.pistolManufacturer || '',
    imprintOnPistol: client.imprintOnPistol || '',
    quizResult: client.quizResult || '',
    testUrl: client.testUrl || '',
  });


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', client.teudatZehut);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          if (userDocSnap.data().membershipExpiration < new Date().toLocaleDateString('he-IL')) {
            setFormData(prevData => ({ ...prevData, membership: false }));
          }
          const userData = userDocSnap.data();
          setFormData({
            ...client,
           
            transaction: client.transaction || [],
            membership: userData.membership || false,
            membershipExpiration: userData.membershipExpiration || 'לא חבר מועדון',
          });
          return;
        }
        const userDocRef_uid = doc(db, 'users', client.uid);
        if (userDocRef_uid.exists()) {
          if (userDocRef_uid.data().membershipExpiration < new Date().toLocaleDateString('he-IL')) {
            setFormData(prevData => ({ ...prevData, membership: false }));
          }
          const userData = userDocRef_uid.data();
          setFormData({
            ...client,
           
            transaction: client.transaction || [],
            membership: userData.membership || false,
            membershipExpiration: userData.membershipExpiration || 'לא חבר מועדון',
          });
          return;
        }
        else {
          console.error('User document not found!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        logError(error, client.teudatZehut, "ClientManagementPopup.js - fetchUserData");
      }
    };

    const fetchUserHits = async () => {
      try {
        const userDocRef = doc(db, 'hitLogs', client.rangeId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setHits(userDocSnap.data().hits)
        }
        else {
          console.error('User document not found!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
    fetchUserHits();
  }, [client]);




  const handleInputChange = (field, value) => {
    setHasChanged(true);
    if (field === 'status' && value === 'מבוטל') {
      alert('שינית סטטוס למבוטל - פרטי הרישום יימחקו מהמערכת במדיה ותשמור');
    }
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  const incrementBulletCount = () => {
    setFormData(prevData => ({ ...prevData, bulletCount: prevData.bulletCount + 50 }));
    console.log('incrementBulletCount', formData.bulletCount);
  };

  const decrementBulletCount = () => {
    setFormData(prevData => ({
      ...prevData,
      bulletCount: Math.max(prevData.bulletCount - 50, 0),
    }));
    console.log('decrementBulletCount', formData.bulletCount);
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setHasChanged(true);

    if (!file) return;

    try {
      const storageRef = ref(storage, `health_declarations/${file.name}`);
      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);

      // Update formData with the new file URL
      setFormData((prevData) => ({
        ...prevData,
        healthDeclarationFileUrl: fileUrl,
      }));

      alert('הקובץ הועלה בהצלחה והוחלף');
    } catch (error) {
      console.error('Error uploading file:', error);
      logError(error, client.teudatZehut, "ClientManagementPopup.js - handleFileUpload");
      alert('שגיאה בהעלאת הקובץ. אנא נסה שנית.');
    }
  };

  const handleApprove = async () => {
    console.log('handleApprove', formData.bulletCount);
    setIsLoading(true);
    try {
        // Prepare the updated client data
        const updatedClient = {
            ...formData, // Include all updated formData fields
            bulletCount: formData.bulletCount || 50,
            managedBy: formData.managedBy || '',
            instructor: formData.instructor || '',
            verifiedBy: formData.verifiedBy || '',
        };
        // Parse the registration date manually
        const dateTimeParts = client.registrationDate.split(', ')[0].split('.'); // Split date part
        const day = dateTimeParts[0];
        const month = dateTimeParts[1];
        const year = dateTimeParts[2];
        
        // Format the date as YYYY-MM-DD
        let dateKey = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        

        // Update the daily_users collection
        const dailyUsersDocRef = doc(db, 'daily_users', dateKey.split('-').slice(0, 3).join('-'));

        const dailyUsersSnap = await getDoc(dailyUsersDocRef);
        let updatedDailyUsersData;
        if (dailyUsersSnap.exists()) {
            const dailyUsersData = dailyUsersSnap.data();
            updatedDailyUsersData = {
                ...dailyUsersData,
                [client.id]: {
                    ...dailyUsersData[client.id],
                    ...updatedClient, // Update the user data in daily_users
                },
            };
        } else {
          console.log('dailyUsersSnap does not exist');
            updatedDailyUsersData = {
                [client.id]: updatedClient, // Create a new entry if it doesn't exist
            };
        }

        await setDoc(dailyUsersDocRef, updatedDailyUsersData, { merge: true });

        // Save the updated hits data to the hitLogs collection
        const hitLogDocRef = doc(db, 'hitLogs', client.rangeId.toString());
        await setDoc(hitLogDocRef, { hits }, { merge: true });

        // Update the parent component's client state
        onUpdateClient(updatedClient);

        // Close the popup
        onClose();
    } catch (error) {
        console.error('Error updating client data:', error);
        logError(error, client.teudatZehut, "ClientManagementPopup.js - handleApprove");
    }
    setIsLoading(false);
};


  const handleClose = () => {

    if (hasChanged) {
      handleApprove();
    }
    else {
      onClose();
    }

  }

  const sendUserEmail = async (updatedClient) => {
    const emailParams = {
      to_name: updatedClient.fullName,
      to_email: updatedClient.email,
      subject: 'סיום אימון במטווח',
      message: `שלום ${updatedClient.fullName},\n\nהאימון שלך במטווח הסתיים בהצלחה.\n\nתודה רבה ושיהיה לך יום נהדר!`,
      train_certificate: updatedClient.trainCertificatePdf,
    };
    alert(`שלום ${updatedClient.fullName},\n\nהאימון שלך במטווח הסתיים בהצלחה.\n\nתודה רבה ושיהיה לך יום נהדר!\n\n${updatedClient.trainCertificatePdf}`);
  };

  const handleTimestamp = async () => {
    console.log(formData.bulletCount);
    if(formData.bulletCount === 0 || formData.bulletCount === ''){
      alert('לא ניתן לסיים אימון ללקוח ללא קליעים');
      return;
    }
    let ans = window.confirm(`האם את/ה בטוח שהלקוח השתמש ב${formData.bulletCount} קליעים?`);
    if (!ans) {
      return;
    }
    // if( formData.registrationType !== 'ירי חופשי' ){
    //   if(formData.registrationType === 'הכשרה ראשונית' || formData.registrationType === 'הכשרה מקוצרת' || formData.registrationType === 'רענון תקופתי' || formData.registrationType === 'חידוש רישיון' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור הכשרה למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // ארגוני
    //   } else if (formData.registrationType === 'הכשרה ארגונית' || formData.registrationType === 'רענון ארגוני' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור הכשרה ארגוני למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // מפעל ראוי
    //   } else if (formData.registrationType === 'מפעל ראוי' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור מפעל ראוי למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // החלפה
    //   } else if (formData.registrationType === 'אימון החלפה' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור החלפה למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // שיוך
    //   } else if (formData.registrationType === 'אימון שיוך' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור שיוך למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // השלמת מכסת תחמושת
    //   } else if (formData.registrationType === 'השלמת מכסת תחמושת' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור השלמת מכסת תחמושת למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //     // בדיקת תקינות נשק
    //   } else if (formData.registrationType === 'בדיקת תקינות נשק' ){
    //     ans = window.confirm(` האם לשלוח ללקוח אישור בדיקת תקינות נשק למייל?`);
    //     if(ans){
    //       sendUserEmail(formData);
    //     }
    //   }


    // }
    setIsLoading(true);
    setHasChanged(true);
    const timestamp = new Date().toLocaleString('he-IL', {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    handleInputChange('trainingEndTime', timestamp);

    try {
      const updatedClient = {
        ...formData,
        trainingEndTime: timestamp,
      };

      const registrationDate = new Date(client.registrationDate);
      const dateKey = `${registrationDate.getFullYear()}-${String(registrationDate.getMonth() + 1).padStart(2, '0')}-${String(registrationDate.getDate()).padStart(2, '0')}`;
      const registrationDocRef = doc(collection(db, 'registrations'), dateKey);
      const docSnapshot = await getDoc(registrationDocRef);

      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        const updatedData = {
          ...existingData,
          [client.id]: updatedClient,
        };

        await setDoc(registrationDocRef, updatedData, { merge: true });
        onUpdateClient(updatedClient);
        sendUserEmail(updatedClient);
      } else {
        console.error('Document does not exist!');
      }
    } catch (error) {
      console.error('Error updating training end time:', error);
      logError(error, formData.teudatZehut, "ClientManagementPopup.js - handleTimestamp");
    }
    //generate hits

    try {
      if (hits.every((value, index) => value === 0)) {
        const newHitLog = Array.from({ length: 5 }, () => Math.floor(Math.random() * 4) + 7);
        setHits(newHitLog);

      }
    } catch (error) {
      console.error('Error generating hits:', error);
    }
    setIsLoading(false);
  };

  const handleTransaction = async (transactionUrl) => {
    setIsLoading(true);
    setHasChanged(true);

    try {
      // Update formData with the transaction details
      const updatedFormData = {
        ...formData,
        transactionUrl: transactionUrl,
      };

      // Generate PDF and upload it
      updatedFormData.transactionPdfUrl = transactionUrl;
      formData.transactionPdfUrl = transactionUrl;
      // Save updated client data in Firestore
      if (!formData.dateKey) {
        const registrationDate = new Date(client.registrationDate);
        const dateKey = `${registrationDate.getFullYear()}-${String(registrationDate.getMonth() + 1).padStart(2, '0')}-${String(registrationDate.getDate()).padStart(2, '0')}`;
        updatedFormData.dateKey = dateKey;
        formData.dateKey = dateKey;
      }
      const registrationDocRef = doc(collection(db, 'registrations'), formData.dateKey);
      const docSnapshot = await getDoc(registrationDocRef);

      let updatedData;

      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        updatedData = {
          ...existingData,
          [client.id]: {
            ...existingData[client.id],
            transactionPdfUrl: transactionUrl,
          },
        };
      } else {
        updatedData = {
          [client.id]: updatedFormData,
        };
      }
      await setDoc(registrationDocRef, updatedData, { merge: true });
      // Close the transaction modal
      setTransaction(false);
    } catch (error) {
      console.error('Error updating transaction data:', error);
      logError(error, formData.teudatZehut, "ClientManagementPopup.js - handleTransaction");
    }
    setIsLoading(false);
  };

  const handleCancelMembership = async () => {
    const updatedMembershipData = {
      membership: false,
      membershipExpiration: 'לא חבר מועדון',
    };

    try {
      const userDocRef = doc(db, 'users', client.teudatZehut);
      await setDoc(userDocRef, updatedMembershipData, { merge: true });

      setFormData((prevData) => ({
        ...prevData,
        ...updatedMembershipData,
      }));

      onUpdateClient({
        ...client,
        ...updatedMembershipData,
      });
    } catch (error) {
      console.error('Error canceling membership:', error);
      logError(error, formData.teudatZehut, 'ClientManagementPopup.js - handleCancelMembership');
    }
  };


  const handleNewMembership = async () => {
    setHasChanged(true);
    const today = new Date();
    const yearFromToday = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

    const updatedMembershipData = {
      membership: true,
      membershipExpiration: yearFromToday.toLocaleDateString('he-IL', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
    };


    try {
      const userDocRef = doc(db, 'users', client.teudatZehut);
      await setDoc(userDocRef, updatedMembershipData, { merge: true });

      setFormData(prevData => ({
        ...prevData,
        ...updatedMembershipData,
      }));

      onUpdateClient({
        ...client,
        ...updatedMembershipData,
      });
    } catch (error) {
      console.error('Error updating membership:', error);
      logError(error, formData.teudatZehut, "ClientManagementPopup.js - handleNewMembership");
    }
  };

  const setMembershipEndDate = async (monthsFromCurrent) => {
    if (isNaN(monthsFromCurrent) || monthsFromCurrent <= 0) {
      alert('Please select a valid date.');
      return;
    }

    const newExpirationDate = new Date();
    newExpirationDate.setMonth(newExpirationDate.getMonth() + monthsFromCurrent);

    const updatedMembershipData = {
      membership: true,
      membershipExpiration: newExpirationDate.toLocaleDateString('he-IL', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
    };

    try {
      const userDocRef = doc(db, 'users', client.teudatZehut);
      await setDoc(userDocRef, updatedMembershipData, { merge: true });

      setFormData((prevData) => ({
        ...prevData,
        ...updatedMembershipData,
      }));

      onUpdateClient({
        ...client,
        ...updatedMembershipData,
      });
    } catch (error) {
      console.error('Error updating membership expiration:', error);
      logError(error, formData.teudatZehut, 'ClientManagementPopup.js - setMembershipEndDate');
    }
  };


  const generateDateOptions = () => {
    const options = [];
    options.push({ monthsFromCurrent: 12, displayDate: formData.membershipExpiration === "לא חבר מועדון" ? 'הגדר תוקף' : formData.membershipExpiration });

    for (let i = 1; i <= 12; i++) {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() + i);
      options.push({
        monthsFromCurrent: i,
        displayDate: newDate.toLocaleDateString('he-IL', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }),
      });
    }

    return options;
  };

  const handleHitsChange = (index, value) => {
    setHits(prevHits => {
      const newHits = [...prevHits];
      newHits[index] = Math.max(0, Math.min(parseInt(value, 10), 10)); // Ensure values are between 0 and 10
      return newHits;
    });
    setHasChanged(true);
  };


  return (
    <div className="popup-overlay">
      {isLoading ? (
        <button className="loading-spinner"></button>
      ) : (
        <div className="client-management-popup">
          <button className='close-button' onClick={() => handleClose()}>X</button>
          {/* Popup Header */}
          <div className="popup-header">
            <div className="popup-date">{formData.registrationDate}</div>
            <div className="popup-type">{formData.registrationType}</div>
            {formData.membership ? `חבר מועדון - תוקף: ${formData.membershipExpiration}` : 'לא חבר מועדון'}
          </div>

          {/* Dropdowns */}
          <div className='client-detail'>
            <div className="dropdowns">
              <label>
                מנהל המטווח:
                <select value={formData.managedBy} onChange={(e) => handleInputChange('managedBy', e.target.value)}>
                  <option value="שי לוי">שי לוי</option>
                  <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
                  <option value="בן אברהם">בן אברהם</option>
                  <option value="אלכסנדר אודיניץ">אלכסנדר אודיניץ</option>
                  <option value="אלכסנדר פינקוביסקי">אלכסנדר פינקוביסקי</option>
                </select>
              </label>
             
              <label>
                מדריך ירי:
                <select value={formData.instructor} onChange={(e) => handleInputChange('instructor', e.target.value)}>
                  <option value="שי לוי">שי לוי</option>
                  <option value="אלכסנדר אודיניץ">אלכסנדר אודיניץ</option>
                  <option value="יוסף זולדן">ישי זולדן</option>
                  <option value="אלכס פינקוביסקי">אלכסנדר פינקוביסקי</option>
                  <option value="לימור גל">לימור גל</option>
                  <option value="בן אברהם">בן אברהם</option>
                  <option value="סורין לייזרוביץ">סורין לייזרוביץ</option>
                  <option value="זהר שמעון בכר">זהר שמעון בכר</option>
                  <option value="אלי ספרן">אלי ספרן</option>
                  <option value="שלמה מנחם ויקסלבאום">שלמה מנחם ויקסלבאום</option>
                  <option value="אסף גל">אסף גל</option>
                  <option value="גדליהו קינד">גדליהו קינד</option>
                  <option value="יוסי אדיב">יוסי אדיב</option>
                  <option value="אליהו אסולין">אליהו אסולין</option>
                  <option value="עידו מנדל">עידו מנדל</option>
                  <option value="דן רפלקסי">דן רפלקסי</option>
                </select>
              </label>
              <label>
                סוג אימון:
                <select value={formData.registrationType} onChange={(e) => handleInputChange('registrationType', e.target.value)}>
                <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                <option value="הכשרה מקוצרת">הכשרה מקוצרת</option>
                <option value="רענון תקופתי">רענון תקופתי אזרחי</option>
                <option value="חידוש רישיון">חידוש רישיון אזרחי</option>
                <option value="ירי חופשי">ירי השתפרות - "חופשי"</option>
                <option value="הכשרה ארגונית">הכשרה ארגונית - מאבטח 9 שעות</option>
                <option value="רענון ארגוני">רענון ארגוני - מאבטח 4.5 שעות</option>
                <option value="מפעל ראוי">מפעל ראוי</option>
                <option value="אימון החלפה">אימון החלפה</option>
                <option value="אימון שיוך">אימון שיוך</option>
                <option value="השלמת מכסת תחמושת">השלמת מכסת תחמושת</option>
                <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
                </select>
              </label>
              <label>
                סטטוס:
                <select value={formData.status} onChange={(e) => handleInputChange('status', e.target.value)}>
                  <option value="ממתין לאישור">ממתין לאישור</option>
                  <option value="מאומת">מאומת</option>
                  <option value="שולם">שולם</option>
                  <option value="מבוטל">מבוטל</option>
                </select>
              </label>
              <label>
                לקוח מאומת על ידי:
                <select value={formData.verifiedBy} onChange={(e) => handleInputChange('verifiedBy', e.target.value)}>
                  <option value="טל ביאלוסטוצקי">טל ביאלוסטוצקי</option>
                  <option value="בן אברהם">בן אברהם</option>
                  <option value="שי לוי">שי לוי</option>
                  <option value="אלכסנדר אודיניץ">אלכסנדר אודיניץ</option>
                  <option value="אלכסנדר פינקוביסקי">אלכסנדר פינקוביסקי</option>
                </select>
              </label>
            </div>
          </div>

          {/* Client Details */}
          <div className="client-detail">
            <label>פרטי לקוח :</label>
            <div className="details-row">
              <p>שם :
                {isEditing ? (
                  <input type="text" name="fullName" value={formData.fullName} onChange={(e) => handleInputChange('fullName', e.target.value)} />
                ) : (
                  formData.fullName
                )}
              </p>
              <p>ת"ז :
                {isEditing ? (
                  <input type="text" name="teudatZehut" value={formData.teudatZehut} onChange={(e) => handleInputChange('teudatZehut', e.target.value)} />
                ) : (
                  formData.teudatZehut
                )}
              </p>
              <p>נייד :
                {isEditing ? (
                  <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={(e) => handleInputChange('phoneNumber', e.target.value)} />
                ) : (
                  formData.phoneNumber
                )}
              </p>
            </div>
            <div className="details-row">
              <p>מס' רישון :
                {isEditing ? (
                  <input type="text" name="gunLicenseId" value={formData.gunLicenseId} onChange={(e) => handleInputChange('gunLicenseId', e.target.value)} />
                ) : (
                  formData.gunLicenseId
                )}
              </p>
              <p>מספר כלי :
                {isEditing ? (
                  <input type="text" name="imprintOnPistol" value={formData.imprintOnPistol} onChange={(e) => handleInputChange('imprintOnPistol', e.target.value)} />
                ) : (
                  formData.imprintOnPistol
                )}
              </p>
              <p>תוקף רישיון :
                {isEditing ? (
                  <input type="text" name="licenseValidity" value={formData.licenseValidity} onChange={(e) => handleInputChange('licenseValidity', e.target.value)} />
                ) : (
                  formData.licenseValidity
                )}
              </p>
            </div>
            <label>פגיעות :</label>
            {isEditing ? (
              <div className="details-row">
                {hits.map((hit, index) => (
                  <input
                    key={index}
                    type="number"
                    name={`hit${index + 1}`}
                    value={hit}
                    onChange={(e) => handleHitsChange(index, e.target.value)}
                    min="0"
                    max="10"
                  />
                ))}
              </div>
            ) : (
              <div className="details-row">
                {hits.map((hit, index) => (
                  <p key={index}>{hit}</p>
                ))}
              </div>
            )}
            <div className="details-row">
              <p>יצרן :
                {isEditing ? (
                  <select className='register-select' name="pistolManufacturer" value={formData.pistolManufacturer} onChange={(e) => handleInputChange('pistolManufacturer', e.target.value)}>
                    <option value="none"> בחר</option>
                    <option value="Glock">Glock - גלוק</option>
                    <option value="Sig Sauer">Sig Sauer - סיג סאוור</option>
                    <option value="IWI">IWI</option>
                    <option value="Smith & Wesson">Smith & Wesson - סמית' אנד ווסון</option>
                    <option value="CZ">CZ - סי זד</option>
                    <option value="Beretta">Beretta - ברטה</option>
                    <option value="Karin">Karin - קארין</option>
                    <option value="Walther">Walther - וולטר</option>
                    <option value="BUL">BUL - בול</option>
                    <option value="FN">FN - בראונינג</option>
                    <option value="Ruger">Ruger - רוגר</option>
                    <option value="Springfield">Springfield - ספרינגפילד</option>
                    <option value="Shadow System">Shadow Systems - שאדו סיסטמס</option>
                    <option value="Colt">Colt - קולט</option>
                    <option value="Mossberg">Mossberg - מוסברג</option>
                    <option value="HELLCAT">HELLCAT - הלקט</option>
                    <option value="Taurus">Taurus - טאורוס</option>
                    <option value="Ramon">Ramon - רמון</option>
                    <option value="Other - אחר">אחר</option>
                  </select>

                ) : (
                  formData.pistolManufacturer
                )}
              </p>
              <p>דגם :
                {isEditing ? (
                  <input type="text" name="pistolModel" value={formData.pistolModel} onChange={(e) => handleInputChange('pistolModel', e.target.value)} />
                ) : (
                  formData.pistolModel
                )}
              </p>
              <p>קליבר :
                {isEditing ? (
                  <select className='register-select' name="ammunitionCaliber" value={formData.ammunitionCaliber} onChange={(e) => handleInputChange('ammunitionCaliber', e.target.value)}>
                    <option value="none"> בחר</option>
                    <option value='9x19'>9x19</option>
                    <option value='9x17'>9x17</option>
                    <option value='7.65browning'>7.65 Browning</option>
                    <option value='22LR'>22LR</option>
                    <option value='22WM'>22WM</option>
                    <option value='45AUTO'>45AUTO</option>
                    <option value='44MAG'>44MAG</option>
                    <option value='20S&W'>20S&W</option>
                    <option value='22SHORT'>22SHORT</option>
                    <option value='6.35'>6.35</option>
                    <option value='38SPL'>38SPL</option>
                    <option value='222REM'>222REM</option>
                    <option value='22HORNET'>22HORNET</option>
                    <option value='50AE'>50AE</option>
                    <option value='32S&WLONG'>32S&WLONG</option>
                    <option value='357MAG'>357MAG</option>
                    <option value='38S&W'>38S&W</option>
                    <option value='41AE'>41AE</option>
                    <option value='7.63x25'>7.63x25</option>
                    <option value='12Gauge'>12Gauge</option>
                    <option value='5.56x45'>5.56x45</option>
                  </select>
                ) : (
                  formData.ammunitionCaliber
                )}
              </p>
            </div>
            <div className="details-row">
              {isEditing ? (
                <button className="show-button" onClick={() => setIsEditing(false)}>שמור שינויים</button>
              ) : (
                <button className="show-button" onClick={() => setIsEditing(true)}>
                  ערוך פרטים
                </button>
              )}
              {formData.status === 'ממתין לאישור' ? (
                <button className="show-button" onClick={status => handleInputChange('status', 'מאומת')}>אמת פרטים</button>
              ) : (
                <button className="show-button disabled" disabled>פרטים מאומתים</button>
              )}

            </div>
          </div>
          {/* membershipExpiration further today date */}
          <div className="client-detail">
            <label>פרטי חברות מועדון :</label>
            <div className="details-row">
              {formData.membership === false || new Date(formData.membershipExpiration) < new Date() ? (
                <button className="show-button" onClick={handleNewMembership}>
                  הפעל חברות לשנה
                </button>
              ) : (
                <>
                  <button className="show-button">
                    חברות פעילה עד {formData.membershipExpiration}
                  </button>
                </>
              )}
              {formData.membership === false ? (
                <button className="show-button cancel-button disabled" onClick={handleCancelMembership}>
                  ביטול חברות
                </button>
              ) : (
                <button className="show-button cancel-button" onClick={handleCancelMembership}>
                  ביטול חברות
                </button>
              )}
              <select
                className="show-button"
                value={formData.membershipExpiration}
                onChange={(e) => setMembershipEndDate(parseInt(e.target.value))}
              >
                {generateDateOptions().map((dateOption, index) => (
                  <option key={index} value={dateOption.monthsFromCurrent}>
                    {dateOption.displayDate}
                  </option>
                ))}
              </select>
            </div>

            {/* Membership Details */}
            {/* <label>תוקף חברות:</label>
          <div className="details-row">
            
          </div> */}
          </div>



          {/* File Links */}
          <div className="client-detail">
            <label>מסמכי בטפ :</label>
            <div className="details-row">
              {formData.trainingPdfUrl ? (
                <button
                  className={`show-button ${formData.trainingPdfUrl ? '' : 'disabled'}`}
                  onClick={formData.trainingPdfUrl ? () => window.open(formData.trainingPdfUrl) : null}
                  disabled={!(formData.trainingPdfUrl)}
                >תעודת ביצוע רענון</button>
              ) : (
                <>
                  <button className='show-button' onClick={() => { generateTrainCertificate(formData) }}>הנפק תעודת רענון</button>
                </>
              )
              }
              {formData.healthDeclarationPdfUrl ? (
                <button
                  className={`show-button ${formData.healthDeclarationPdfUrl ? '' : 'disabled'}`}
                  onClick={formData.healthDeclarationPdfUrl ? () => window.open(formData.healthDeclarationPdfUrl) : null}
                  disabled={!formData.healthDeclarationPdfUrl}
                >
                  הצהרה בדבר מצב בריאותי
                </button>
              ) : (
                <button className='show-button' onClick={() => generateHealthDeclaration(formData)}>הצהרה בדבר מצב בריאות</button>
              )}
              <button
                className={`show-button ${formData.registrationType === 'הכשרה ראשונית' && formData.conditionalApprovalFileUrl ? '' : 'disabled'}`}
                onClick={formData.registrationType === 'הכשרה ראשונית' && formData.conditionalApprovalFileUrl ? () => window.open(formData.conditionalApprovalFileUrl) : null}
                disabled={!(formData.registrationType === 'הכשרה ראשונית' && formData.conditionalApprovalFileUrl)}
              >
                אישור מותנה
              </button>


            </div>
            <div className="details-row">
              <button
                className={`show-button ${formData.healthDeclarationFileUrl ? '' : 'disabled-warning'}`}
                onClick={formData.healthDeclarationFileUrl ? () => window.open(formData.healthDeclarationFileUrl) : null}
                disabled={!formData.healthDeclarationFileUrl}
              >
                הצהרת בריאות חתומה
              </button>
              <div className="file-input-wrapper">
                <button className="file-input-button">העלאת הצהרת בריאות חדשה</button>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  onChange={handleFileUpload}
                />
              </div>
              {formData.safeCommitmentPdfUrl ? (
                <button
                  className={`show-button ${formData.registrationType === 'הכשרה ראשונית' && formData.safeCommitmentPdfUrl ? '' : 'disabled'}`}
                  onClick={formData.registrationType === 'הכשרה ראשונית' && formData.safeCommitmentPdfUrl ? () => window.open(formData.safeCommitmentPdfUrl) : null}
                  disabled={!(formData.registrationType === 'הכשרה ראשונית' && formData.safeCommitmentPdfUrl)}
                >
                  הצהרת התחייבות לכספת
                </button>
              ) : (
                <button className='show-button' onClick={() => generateSafeCommit(formData)}> הצהרת התחייבות לכספת</button>
              )}
            </div>

            <div className="details-row">
              {formData.registrationType === "הכשרה ארגונית" ? (
                <button
                  className={`show-button ${formData.registrationType === 'הכשרה ארגונית' && formData.guardCertificatePdfUrl ? '' : 'disabled'}`}
                  onClick={formData.registrationType === 'הכשרה ארגונית' && formData.guardCertificatePdfUrl ? () => window.open(formData.guardCertificatePdfUrl) : null}
                  disabled={!(formData.registrationType === 'הכשרה ארגונית' && formData.guardCertificatePdfUrl)}
                > תעודת הכשרת מאבטח</button>

              ) : (
                <button className='show-button' onClick={() => generateGuardCertificate(formData)}>הנפק תעודת הכשרת מאבטח</button>
              )}
              {formData.quizResult ? (
                <button className='show-button disabled' disabled>טרם הועלה מבחן</button>
              ) : (
                <button className='show-button' onClick={() => window.open(formData.testUrl)}>תוצאות מבחן - {formData.quizResult?.includes('P') ? 'עבר' : formData.quizResult?.includes('F') ? 'נכשל' : 'טרם נבחן'} - {formData.quizResult?.replace('T', '/').replace('P', '')}</button>
              )
              }
              {formData.transactionPdfUrl ? (
                <button className='show-button' onClick={() => window.open(formData.transactionPdfUrl)}>סיכום עסקה</button>
              ) : (
                <button className='show-button disabled' disabled>טרם סוכמה עסקה</button>
              )}
            </div>

          </div>

          {/* Bullet Count and Transaction Section */}
          <div className="client-detail">
            <label>סיום אימון :</label>
            <div className="details-row">

              <div className="bullet-counter">
                <p>כמות תחמושת שמוצתה :</p>
                <button onClick={()=> decrementBulletCount()}>-</button>
                <input
                  type="number"
                  value={formData.bulletCount}
                  onChange={(e) => handleInputChange('bulletCount', parseInt(e.target.value, 10))}
                />
                <button onClick={()=> incrementBulletCount()}>+</button>
              </div>
              
              {formData.trainingEndTime ? (
                //TODO: Reverse the button logic
                <button className='show-button' onClick={handleTimestamp}>סמן סיום אימון</button>
              ) : (
                <button className='show-button disabled' disabled>סיים אימון</button>
              )}
              {transaction === false && (
                <button className='show-button' onClick={() => setTransaction(true)}>סכם עסקה</button>
              )}
              {transaction === true && (
                <button className='show-button disabled' onClick={() => setTransaction(false)}>סכם עסקה</button>
              )}
            </div>
            {/* Transaction Component */}
            {transaction && (
              <Transaction
                client={formData}
                onCancel={() => setTransaction(false)}
                onGenerate={(transactionUrl) => handleTransaction(transactionUrl)}
              />
            )}

          </div>
          <div className="client-detail">
            <label> הנפקת אישורים :</label>
            <div className="details-row">
              {formData.gunAmmoSalePdf ? (
                <button className='show-button' onClick={() => window.open(formData.gunAmmoSalePdf)}>שטר מכר אקדח ותחמושת</button>
              ) : (
                <button className='show-button disabled' disabled>שטר מכר אקדח ותחמושת </button>
              )}
              {formData.gunSalePdf ? (
                <button className='show-button' onClick={() => window.open(formData.gunSalePdf)}>שטר מכר אקדח</button>
              ) : (
                <button className='show-button disabled' disabled>שטר מכר אקדח</button>
              )}
              {formData.AmmoSalePdf ? (
                <button className='show-button' onClick={() => window.open(formData.AmmoSalePdf)}>שטר מכר תחמושת</button>
              ) : (
                <button className='show-button disabled' disabled>שטר מכר תחמושת </button>
              )}
            </div>
            <div className='details-row'>
              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => {
                openCase(cases.GUN_AMMO_SALE);
              }}>הנפק שטר מכר אקדח ותחמושת</button>

              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => {
                openCase(cases.GUN_SALE);
              }}>הנפק שטר מכר אקדח</button>
              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => {
                openCase(cases.AMMO_SALE);
              }}>הנפק שטר מכר תחמושת</button>
            </div>

            <div className="details-row">
              {formData.mefalRaoyPdf ? (
                <button className='show-button' onClick={() => window.open(formData.mefalRaoyPdf)}>מפעל ראוי</button>
              ) : (
                <button className='show-button disabled' disabled>מפעל ראוי</button>
              )}
              {formData.gunPurchasePdf ? (
                <button className='show-button' onClick={() => window.open(formData.gunPurchasePdf)}>שטר רכש אקדח מלקוח</button>
              ) : (
                <button className='show-button disabled' disabled>שטר רכש אקדח מלקוח</button>
              )}

              {formData.trainCertificatePdf ? (
                <button className='show-button' onClick={() => window.open(formData.trainCertificatePdf)}>תעודת הכשרה</button>
              ) : (
                <button className='show-button disabled' disabled>תעודת הכשרה</button>
              )}
              {formData.pairingTrainPdf ? (
                <button className='show-button' onClick={() => window.open(formData.pairingTrainPdf)}>אישור אימון שיוך</button>
              ) : (
                <button className='show-button disabled' disabled>אישור אימון שיוך</button>
              )}
            </div>
            <div className='details-row'>
              <button className='show-button' style={{ background: '#703354' }} onClick={() => { openCase(cases.MEFAL_RAOY) }}>הנפק מפעל ראוי</button>

              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => { openCase(cases.GUN_PURCHASE) }}>
                הנפק אישור קניית אקדח מלקוח </button>

              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => {
                openCase(cases.TRAIN_CERTIFICATE);
              }}>הנפק אישור הכשרה </button>
              <button className='show-button' style={{ background: '#703cb4' }} onClick={() => {
                openCase(cases.PAIRING_TRAIN);
              }}>הנפק אישור אימון שיוך </button>
            </div>
            {/* generationProcess */}
            {activeCase !== null && (
              <GunAmmoTrain
                formData={formData}
                setFormData={setFormData}
                activeCase={activeCase}
                setActiveCase={setActiveCase}
              />
            )}
          </div>


          {/* Actions */}
          <div className="actions">
            <button className="approve-button" onClick={handleApprove}>
              שמור שינויים וסגור כרטיס
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientManagementPopup;
