import React from "react";
import { db } from "./firebase"; // Ensure Firebase is initialized
import {
  collection,
  getDocs,
  setDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

const DailyUserMigration = () => {
  const handleMigration = async () => {
    try {
      const registrationsRef = collection(db, "registrations");
      const snapshot = await getDocs(registrationsRef);
      const dailyUsersData = {}; // Temp storage for transformed data
      const skippedNumbers = new Set(); // Track skipped rangeIds
      const usedRangeIds = new Set();

      let counter = 0;

      // Step 1: Process all registrations
      snapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        const rawKey = docSnapshot.id; // Date key: '2024-08-28', '2024-08-28-12'

        // Normalize the key to 'YYYY-MM-DD'
        const dateKey = rawKey.substring(0, 10);

        if (!dailyUsersData[dateKey]) {
          dailyUsersData[dateKey] = {}; // Initialize object for this date
        }

        // Step 2: Process rangeIds, exclude 'signatureUrl'
        Object.keys(data).forEach((rangeId) => {
          counter++;
          usedRangeIds.add(parseInt(rangeId));
          const rangeData = { ...data[rangeId] };
          delete rangeData.signatureUrl; // Exclude the signatureUrl field

          // Store directly under rangeId
          dailyUsersData[dateKey][rangeId] = rangeData;
        });
      });

      // Step 3: Calculate skipped rangeIds
      for (let i = 1; i <= counter; i++) {
        if (!usedRangeIds.has(i)) {
          skippedNumbers.add(i);
        }
      }

      // Step 4: Write transformed data to 'daily_users'
      const dailyUsersRef = collection(db, "daily_users");
      const writePromises = Object.entries(dailyUsersData).map(
        async ([dateKey, rangeList]) => {
          const docRef = doc(dailyUsersRef, dateKey);
          await setDoc(docRef, rangeList);
        }
      );

      // Step 5: Update metadata collection
      const metadataRef = doc(db, "metadata", "2024");
      const metadataSnapshot = await getDoc(metadataRef);
      const metadataData = metadataSnapshot.exists() ? metadataSnapshot.data() : {};

      const num_of_skip = skippedNumbers.size;
      const skipped_numbers = Array.from(skippedNumbers);

      await updateDoc(metadataRef, {
        num_of_skip,
        skipped_numbers,
      });

      await Promise.all(writePromises);
      alert("Migration complete!");
    } catch (error) {
      console.error("Error during migration:", error);
      alert("Migration failed. Check console for details.");
    }
  };

  return (
    <div>
      <button onClick={handleMigration} style={{marginTop:200}}>Migrate Registrations</button>
    </div>
  );
};

export default DailyUserMigration;
