import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Topbar from './components/Topbar.js';
import Welcome from './components/Welcome.js';
import Footer from './components/Footer.js';
import Content from './components/Content.js';
import Management from './components/Management';
import AboutUs from './components/AboutUs';
import NewLicense from './components/NewLicense';
import OurGuns from './components/OurGuns';
import WeaponReviews from './components/WeaponReviews';
import Unsubscribe from './components/Unsubscribe';
import Register from './components/Register';
import OCR from './components/OCR';
import ClientList from './components/ClientList';
import QuizComponent from './components/QuizComponent';
import TestResults from './components/TestResults';
import GunRecive from './components/GunRecive.js';
import ShooterLog from './components/ShooterLog.js';
import GunCollection from './components/GunCollection.js';
import DailyUserMigration from './components/DailyUserMigration.js';
import ShootingLedger from './components/ShootingLedger.js';
import DatesList from './components/DatesList.js';
import UsedPistols from './components/UsedPistols.js';
import Ledger from './components/Ledger.js';
import HittingLog from './components/HittingLog.js';
import UpdateNotification from './components/UpdateNotification.js';

import { LanguageProvider, useLanguage } from './contexts/LanguageContext.js';
import { addMember, getUserRole, auth, db } from './components/firebase.js';
import { logError } from './components/firebase.js';
import { doc, getDoc } from 'firebase/firestore';

import './styles/App.css';
import emailjs from 'emailjs-com';
import { HelmetProvider, Helmet } from 'react-helmet-async';

emailjs.init('cZhzGdYGZLShYtlbV');

const App = () => {
  return (
    <Router>
      <LanguageProvider>
        <MainApp />
      </LanguageProvider>
    </Router>
  );
};

const MainApp = () => {
  const { language } = useLanguage();
  const [activeContent, setActiveContent] = useState(null);
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isOutdated, setIsOutdated] = useState(false);
  const navigate = useNavigate();

  const localVersion = "2.3.3"; // Replace with your current version

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const docRef = doc(db, 'metadata', 'version'); // Adjust Firestore path
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const firestoreVersion = docSnap.data().currentVersion;
          if (firestoreVersion !== localVersion) {
            setIsOutdated(true); // Mark app as outdated if versions differ
          }
        } else {
          console.error('No version document found in Firestore!');
        }
      } catch (error) {
        console.error('Error checking app version:', error);
      }
    };

    checkVersion();
  }, [localVersion]);

  const handleRefresh = () => {
    window.location.reload(true); // Force a hard refresh
  };

  useEffect(() => {
    // Load theme preference from local storage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'light') {
      document.body.classList.remove('dark-mode');
      setIsDarkMode(false);
    } else {
      document.body.classList.add('dark-mode');
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const role = await getUserRole(user);
        setUserRole(role);
      } else {
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
      if (!hasSeenWelcome) {
        setIsWelcomeVisible(true);
        localStorage.setItem('hasSeenWelcome', 'true');
      }
    }
  }, [navigate]);

  const handleCloseWelcome = () => {
    setIsWelcomeVisible(false);
  };

  const handleShowWelcome = () => {
    setIsWelcomeVisible(true);
  };

  const handleLogin = async (user) => {
    const wantsUpdates = window.confirm('Do you want to receive updates and news?');

    try {
      await addMember(user, wantsUpdates);

      const role = await getUserRole(user);
      setUserRole(role);
    } catch (error) {
      console.error('Error adding document: ', error);
      logError(error, user.teudatZehut, 'App.js - handleLogin');
    }
  };

  const handleManagerAccess = (path) => {
    if (userRole === 'manager') {
      navigate(path);
    } else {
      navigate('/');
    }
  };

  return (
    <div id="root" className={`app ${language === 'he' ? 'rtl' : 'ltr'}`}>
      <HelmetProvider>
        <Helmet>
          <title>מטווח שי לוי - קרני שומרון</title>
          <meta name="description" content="מטווח שי לוי - קרני שומרון, ביקורות אקדחים, אימונים, חידוש רישיון ועוד" />
          <meta
            name="keywords"
            content=", נרתיק לאקדח, זיג, Sig Sauer, הלקט , אקדח חדש, אקדח משומש דיקת תיקנות נשק, רענון תקופתי, מטווח , מטווח שי לוי , הכשרה ראשונית , רישיון לאקדח, גלוק, Glock, מאבטחים, חידוש רישיון, קרני שומרון"
          />
        </Helmet>
        <Topbar onLogin={handleLogin} onShowWelcome={handleShowWelcome} user={user} handleManagerAccess={handleManagerAccess} />
        {isWelcomeVisible && <Welcome setActiveContent={setActiveContent} onClose={handleCloseWelcome} />}
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/license" element={<NewLicense />} />
            <Route path="/guns" element={<OurGuns user={user} />} />
            <Route path="/weapon-reviews" element={<WeaponReviews />} />
            <Route path="/management" element={userRole === 'manager' ? <Management /> : <Content />} />
            <Route path="/ledger" element={userRole === 'manager' ? <ShootingLedger /> : <Content />} />
            <Route path="/migration" element={userRole === 'manager' ? <DailyUserMigration /> : <Content />} />
            <Route path="/shooter-log" element={userRole === 'manager' ? <ShootingLedger /> : <Content />} />
            <Route path="/gun-collection" element={userRole === 'manager' ? <GunCollection /> : <Content />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/register" element={<Register />} />
            <Route path="/הרשמה" element={<Register />} />
            <Route path="/hitting-log" element={userRole === 'manager' ? <HittingLog /> : <Content />} />
            <Route path="/ocr" element={<OCR />} />
            <Route path="/clientList" element={userRole === 'manager' ? <DatesList /> : <Content />} />
            <Route path="/test_results" element={userRole === 'manager' ? <TestResults /> : <Content />} />
            <Route path="/usedPistols" element={<UsedPistols />} />
            <Route path="/quiz" element={<QuizComponent />} />
            <Route path="/pickup" element={<GunRecive />} />
            <Route path="*" element={<Content />} />
          </Routes>
        </div>
        {isOutdated && (
          <UpdateNotification
            message="קיימת גרסה חדשה לאתר - יש לרענן את הדף"
            onRefresh={handleRefresh}
          />
        )}
        <Footer onLogin={handleLogin} onShowWelcome={handleShowWelcome} version={localVersion}/>
      </HelmetProvider>
    </div>
  );
};

export default App;
