import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import '../styles/ShooterLog.css';
import TransactionList from './TransactionList';
import { CSVLink } from 'react-csv';
import ClientManagementPopup from './ClientManagementPopup';

const ShootingLedger = () => {
    const [ledgerEntries, setLedgerEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedClient, setSelectedClient] = useState(null);
    const [totalBulletCount, setTotalBulletCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [bulletCountByType, setBulletCountByType] = useState({});
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [years, setYears] = useState([]);

    useEffect(() => {
        fetchDates();
    }, []);

    useEffect(() => {
        fetchLedgerEntries();
    }, [selectedDate, selectedType, selectedYear]);

    useEffect(() => {
        applyFilters();
    }, [searchQuery, ledgerEntries]);

    const fetchDates = async () => {
        try {
            const snapshot = await getDocs(collection(db, 'daily_users'));
            const uniqueDates = snapshot.docs.map(doc => doc.id).sort().reverse();
            const uniqueYears = [...new Set(uniqueDates.map(date => date.split('-')[0]))].sort().reverse();
            setDates(uniqueDates);
            setYears(uniqueYears);
        } catch (error) {
            console.error('Error fetching dates:', error);
        }
    };

    const fetchLedgerEntries = async () => {
        try {
            setIsLoading(true);
            const snapshot = await getDocs(collection(db, 'daily_users'));
            const allEntries = [];
            let bulletCount = 0;
            const bulletCountByTypeTemp = {};

            snapshot.forEach((docSnap) => {
                const year = docSnap.id.split('-')[0];
                if (year === selectedYear.toString() && (selectedDate === 'all' || docSnap.id === selectedDate)) {
                    const data = docSnap.data();
                    Object.entries(data).forEach(([rangeId, entry]) => {
                        if (selectedType === 'all' || entry.registrationType === selectedType) {
                            allEntries.push({ rangeId, ...entry, dateKey: docSnap.id });
                            bulletCount += parseInt(entry.bulletCount) || 0;
                            if (!bulletCountByTypeTemp[entry.registrationType]) {
                                bulletCountByTypeTemp[entry.registrationType] = 0;
                            }
                            bulletCountByTypeTemp[entry.registrationType] += parseInt(entry.bulletCount) || 0;
                        }
                    });
                }
            });

            const sortedEntries = allEntries.sort((a, b) => b.rangeId - a.rangeId);
            setLedgerEntries(sortedEntries);
            setFilteredEntries(sortedEntries);
            setTotalBulletCount(bulletCount);
            setBulletCountByType(bulletCountByTypeTemp);
            setTotalUsers(sortedEntries.length);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching ledger entries:', error);
        }
    };

    const applyFilters = () => {
        const filtered = ledgerEntries.filter(
            (entry) =>
                (searchQuery === '' ||
                    entry.phoneNumber.includes(searchQuery) ||
                    entry.teudatZehut.includes(searchQuery)) &&
                (selectedType === 'all' || entry.registrationType === selectedType)
        );
        setFilteredEntries(filtered);
    };

    const resetFilters = () => {
        setSearchQuery('');
        setSelectedType('all');
        setSelectedDate('all');
        fetchLedgerEntries();
    };

    const downloadCSV = (entries) => {
        return entries.map((entry) => ({
            'מספר': entry.rangeId,
            'תאריך': entry.registrationDate.split(',')[0],
            'שעה': entry.registrationDate.split(',')[1],
            'שם ומשפחה': entry.fullName,
            'תז': entry.teudatZehut,
            'סיבת הירי': entry.registrationType,
            'הצהרת בריאות': entry.healthDeclarationConfirm ? '✔' : '',
            'תוקף רישיון': entry.licenseValidity,
            'סוג כלי היריה': entry.registrationType === 'הכשרה ראשונית' ? 'גלוק' : entry.pistolManufacturer,
            'בעלות': entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי',
            'מס כלי': entry.imprintOnPistol,
            'קליבר': entry.registrationType === 'הכשרה ראשונית' ? '9x19' : entry.ammunitionCaliber,
            'כמות': entry.bulletCount,
            'שעת סיום האימון': entry.trainingEndTime,
            'חתימת המאמן': entry.verifiedBy,
            'סטטוס': entry.status,
        }));
    };

    const handleRangeIdClick = (entry) => {
        setSelectedClient(entry);
    };

    const closePopup = () => {
        setSelectedClient(null);
        
    };

    const updateClient = (updatedClient) => {
        const updatedEntries = ledgerEntries.map((entry) =>
            entry.rangeId === updatedClient.rangeId ? updatedClient : entry
        );
        setLedgerEntries(updatedEntries);
        applyFilters();
    };

    return (
        <div className="table-container">
            <h2>יומן ירי</h2>
            <div className="controls">
                <div className="table-row">
                    <select value={selectedYear} onChange={(e) => {
                        setSelectedYear(e.target.value);
                        setSelectedDate('all');
                    }}>
                        {years.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </select>
                    <select value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                        <option value="all">כל התאריכים</option>
                        {dates.filter(date => date.startsWith(selectedYear)).map((date, index) => (
                            <option key={index} value={date}>{date}</option>
                        ))}
                    </select>
                    <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        <option value="all">כל הסוגים</option>
                        <option value="הכשרה ראשונית">הכשרה ראשונית</option>
                        <option value="חידוש רישיון">חידוש רישיון</option>
                        <option value="ירי חופשי">ירי חופשי</option>
                        <option value="בדיקת תקינות נשק">בדיקת תקינות נשק</option>
                    </select>
                    <input
                        type="text"
                        placeholder="חפש לפי מספר נייד או תעודת זהות"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button onClick={resetFilters}>איפוס</button>
                    <CSVLink
                        data={downloadCSV(filteredEntries)}
                        filename={`ledger-${selectedDate}.csv`}
                    >
                        <button>הורד גיליון</button>
                    </CSVLink>
                </div>
            </div>
            <div className="stats-grid">
                <div className="stat-item">
                    <h3>תחמושת שנורתה</h3>
                    <p>{totalBulletCount} כדורים</p>
                    <p>חבילות {totalBulletCount / 50}</p>
                </div>
                {Object.entries(bulletCountByType).map(([type, count]) => (
                    <div className="stat-item" key={type}>
                        <h3>תחמושת עבור {type}</h3>
                        <p>{count} כדורים</p>
                        <p> {count / 50} חבילות</p>
                    </div>
                ))}
                <div className="stat-item">
                    <h3>סך הכל נרשמים</h3>
                    <p>{totalUsers}</p>
                </div>
            </div>
            <div className="table-overflow">
                <table className="table-table">
                    <thead>
                        <tr>
                            <th colSpan="6">פרטי היורה</th>
                            <th colSpan="4">סיבת הירי</th>
                            <th colSpan="2"></th>
                            <th colSpan="3">פרטי כלי היריה</th>
                            <th colSpan="2">תחמושת שנורתה</th>
                            <th colSpan="2">פרטי האימון</th>
                        </tr>
                        <tr>
                            <th>מספר</th>
                            <th>תאריך</th>
                            <th>שעה</th>
                            <th>שם ומשפחה</th>
                            <th>תז</th>
                            <th>נייד</th>
                            <th>אימון ואימות</th>
                            <th>חידוש רישיון</th>
                            <th>ירי חופשי</th>
                            <th>בדיקת כלי</th>
                            <th>הצהרת בריאות</th>
                            <th>תוקף רישיון</th>
                            <th>סוג</th>
                            <th>בעלות</th>
                            <th>מס' כלי</th>
                            <th>קליבר</th>
                            <th>כמות</th>
                            <th>שעת סיום</th>
                            <th>סטטוס</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEntries.length > 0 ? (
                            filteredEntries.map((entry, index) => (
                                <tr key={index}>
                                    <td onClick={() => handleRangeIdClick(entry)} className="clickable">
                                        {entry.rangeId}
                                    </td>
                                    <td>{entry.registrationDate.split(',')[0]}</td>
                                    <td>{entry.registrationDate.split(',')[1]}</td>
                                    <td>{entry.fullName}</td>
                                    <td>{entry.teudatZehut}</td>
                                    <td>{entry.phoneNumber}</td>
                                    <td>{entry.registrationType === 'הכשרה ראשונית' ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'חידוש רישיון' ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'ירי חופשי' ? '✔' : ''}</td>
                                    <td>{entry.registrationType === 'בדיקת תקינות נשק' ? '✔' : ''}</td>
                                    <td>{entry.healthDeclarationConfirm ? '✔' : ''}</td>
                                    <td>{entry.licenseValidity}</td>
                                    <td>{entry.pistolManufacturer || ''}</td>
                                    <td>{entry.registrationType === 'הכשרה ראשונית' ? 'מטווח' : 'פרטי'}</td>
                                    <td>{entry.imprintOnPistol || ''}</td>
                                    <td >{entry.ammunitionCaliber || ''}</td>
                                    <td style={{backgroundColor: entry.bulletCount === ' ' ? 'red' : 'none'}}>{entry.bulletCount}</td>
                                    <td>{entry.trainingEndTime?.split(',')[1] || entry.registrationDate?.split(',')[1]}</td>
                                    <td style={{background: entry.status === 'מבוטל' ? 'rgba(255, 0, 0, 0.377)' : entry.status === 'ממתין לאישור' ? 'rgba(255, 196, 0, 0.377)' :
                      entry.status === 'מאומת'? 'rgba(0, 255, 55, 0.377)' : 'inherit'}}>{entry.status}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="19">{isLoading ? 'טוען רשומות...' : 'אין רשומות זמינות'}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {selectedClient && (
                <ClientManagementPopup
                    client={selectedClient}
                    onClose={closePopup}
                    onUpdateClient={updateClient}
                />
            )}
        </div>
    );
};

export default ShootingLedger;